import React from "react";

const legendStyle = {
  position: "absolute",
  bottom: 10,
  left: 10,
  backgroundColor: "white",
  padding: 10,
  borderRadius: 5,
  fontSize: 12,
};

const statusItemStyle = (color) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 5,
  fontSize: 10,
});

const statusColorStyle = (color) => ({
  width: 12,
  height: 12,
  backgroundColor: color,
  borderRadius: "50%",
  marginRight: 5,
  border: "1px solid white",
});

export default function StatusLegend(props) {
  return (
    <div style={legendStyle}>
      <h3>Legend</h3>
      <div style={statusItemStyle("red")}>
        <div style={statusColorStyle("red")}></div>
        <span>Dormant</span>
      </div>
      <div style={statusItemStyle("blue")}>
        <div style={statusColorStyle("blue")}></div>
        <span>In Motion</span>
      </div>
      <div style={statusItemStyle("teal")}>
        <div style={statusColorStyle("teal")}></div>
        <span>Arrived</span>
      </div>
      <div style={statusItemStyle("green")}>
        <div style={statusColorStyle("green")}></div>
        <span>Completed</span>
      </div>
    </div>
  );
}
