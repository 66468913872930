import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import contactDev from "../../assets/imgs/ContactDev.png";

const StyledImageBox = styled(Box)(({ showing }) => ({
  position: "relative",
  display: "inline-block",
  maxWidth: showing ? "200px" : "30px",
}));

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

const TextOverlay = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  paddingBottom: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  color: "#fff",
  padding: "0.5rem",
  textAlign: "center",
});

export default function DeveloperSection(props) {
  return (
    <Box>
      {props.showing && (
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "600", color: "blue.main" }}
          >
            Ambulex
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <StyledImageBox showing={props.showing}>
          <StyledImage src={contactDev} alt="Contact Developer" />
          <TextOverlay>
            <Typography variant="body2" sx={{ marginBottom: "0.2rem" }}>
              Developed by
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              Oakar Services
            </Typography>
            {/* <a
              href="https://osl.co.ke/contact-us/"
              target="_blank"
              rel="noopener noreferrer" 
              style={{ textDecoration: "none" }}
            > */}
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  backgroundColor: "#fff",
                  marginTop: "0.5rem",
                  maxWidth: "200px",
                  fontSize: "0.6rem",
                  padding: "0.5rem 1rem",
                  marginBottom: "0.5rem",
                  borderRadius: "0.5rem",
                //   "&:hover": {
                //     backgroundColor: "primary.main",
                //     color: "#fff",
                //   },
                }}
              >
                Contact Developer
              </Button>
            {/* </a> */}
          </TextOverlay>
        </StyledImageBox>
      </Box>
    </Box>
  );
}
