import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export default function ChangePassword({
  open,
  onClose,
  currentUser,
}) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    password: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const rfOldPassword = useRef();
  const rfNewPassword = useRef();
  const rfConfirmNewPassword = useRef();

  const changePassword = () => {
    const d = {
      password: rfOldPassword.current.value,
      NewPassword: rfNewPassword.current.value,
      ConfirmNewPassword: rfConfirmNewPassword.current.value,
    };

    updateBody(d);
    setIsError("");

    if (!d.password) return setIsError("Old password is required!");
    if (!d.NewPassword || d.NewPassword.length < 6)
      return setIsError("New Password must be at least 6 characters!");
    if (!validatePassword(d.NewPassword, d.ConfirmNewPassword))
      return setIsError("New Password and Confirm New Password do not match");

    setIsLoading(true);
    fetch(`/api/auth/update/${currentUser?.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw new Error("Could not change password");
      })
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          setIsError(data.success);
        } else {
          setIsError(data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError("Could not change password!");
      });
  }
  const validatePassword = (newPassword, confirmNewPassword) => {
    return confirmNewPassword === newPassword;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Change Password for {currentUser?.name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {isError}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            inputRef={rfOldPassword}
            label="Enter Old Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            inputRef={rfNewPassword}
            label="Enter New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            inputRef={rfConfirmNewPassword}
            label="Confirm New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={changePassword}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
