import React, { useState, useEffect } from "react";
import "../../Styles/userhome.scss";
import LandingMap from "../../components/Home/LandingMap";
import InProgressMap from "../../components/Home/InProgressMap";
import GBVMap from "../../components/ReportsPage/GBVMap";
import EMMap from "../../components/ReportsPage/EMMap";
import InputF from "../Util/Input";
import Track from "../Rides/Track";

export default function MapsHome(props) {
  const [active, setActive] = useState("All Reports");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [reports, setReports] = useState([]);

  const fetchReportsByDate = async () => {
    try {
      const response = await fetch(`/api/reports/filter/${start}/${end}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setReports(data);
      } else {
        console.error("Failed to fetch reports");
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  // useEffect(() => {
  //   fetch(`/api/reports/filter/${start}/${end}`)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else throw Error("");
  //     })
  //     .then((data) => {
  //       setReports(data);
  //     })
  //     .catch((err) => {});
  // }, []);

  useEffect(() => {
    if (start && end) {
      fetchReportsByDate();
    }
  }, [start, end]);

  return (
    <div className="userhome">
      <div className="vtop">
        {/* <div className="filter">
          <InputF
            handleChange={(e) => {
              setStart(e);
            }}
            type="date"
            value={start}
            label="Start Date"
          />
          <InputF
            handleChange={(e) => {
              setEnd(e);
            }}
            type="date"
            value={end}
            label="End Date"
          />
        </div> */}
        <div className="container">
          <Item txt="All Reports" active={active} setActive={setActive} />
          <Item txt="EMT Tracking" active={active} setActive={setActive} />
          <Item txt="GBV" active={active} setActive={setActive} />
          <Item txt="ME" active={active} setActive={setActive} />
        </div>
      </div>

      <div>
        {active === "All Reports" && <LandingMap reports={reports} />}
        {active === "EMT Tracking" && <InProgressMap />}
        {active === "GBV" && <GBVMap reports={reports} />}
        {active === "ME" && <EMMap reports={reports} />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <h4>{props.txt}</h4>
    </div>
  );
};
