import React, { createContext, useState, useEffect, useContext } from "react";

// create AuthContext
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  // login function
  const login = async (email, password) => {
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("sdgfsregfd", data.token); // Store the token
        setIsAuthenticated(true); // Assuming you have a state setter for authentication
        return { success: true };
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData.error);
        return {
          success: false,
          message: errorData.error,
        };
      }
    } catch (error) {
      console.error(
        "Login failed:",
        error.response ? error.response.data.error : error.message
      );
      return {
        success: false,
        message: "Login failed",
      };
    }
  };

  useEffect(() => {
    // setLoading(true);
    const token = localStorage.getItem("sdgfsregfd");
    
    if (token) {
      const decoded = require("jsonwebtoken").decode(token);
      setUser(decoded);
    }
  }, []);

  // Logout function
  const logout = () => {
    localStorage.removeItem("sdgfsregfd");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
