import React, { useState, useEffect } from "react";
import "../../Styles/userhome.scss";
import AllEMTs from "./AllEMTs";
import List from "./List";
import RoutingMap from "./RoutingMap";

export default function MapsHome(props) {
  const [active, setActive] = useState("All EMTs");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [reports, setReports] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    Latitude: null,
    Longitude: null,
  });
  // console.log(selectedCoordinates);
  

  const fetchReportsByDate = async () => {
    try {
      const response = await fetch(`/api/reports/filter/${start}/${end}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setReports(data);
      } else {
        console.error("Failed to fetch reports");
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  useEffect(() => {
    if (start && end) {
      fetchReportsByDate();
    }
  }, [start, end]);

  return (
    <div className="userhome">
      <div className="vtop">
        <div className="container">
          <Item txt="All EMTs" active={active} setActive={setActive} />
          <Item txt="EMTs in movement" active={active} setActive={setActive} />
          <Item txt="Routing" active={active} setActive={setActive} />
        </div>
      </div>

      <div>
        {active === "All EMTs" && <AllEMTs />}
        {active === "EMTs in movement" && (
          <List
            setActive={setActive}
            setSelectedCoordinates={setSelectedCoordinates}
          />
        )}
        {active === "Routing" && (
          <RoutingMap coordinates={selectedCoordinates} />
        )}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <h4>{props.txt}</h4>
    </div>
  );
};
