import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function List(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/erteams/motion/status/paginated/In Motion/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [offset]);

  return (
    <Box position={"relative"}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>EMT Organization</TableCell>
              <TableCell>EMT</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Current Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <TableRow
                  key={item.ID}
                  onClick={() => {
                    props.setActive("Routing");
                    props.setSelectedCoordinates({
                      latitude: item.Latitude,
                      longitude: item.Longitude,
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{item.OrganisationName}</TableCell>
                  <TableCell>{item.Name}</TableCell>
                  <TableCell>{item.Phone}</TableCell>
                  <TableCell>{item.Email}</TableCell>
                  <TableCell>{item.Status}</TableCell>
                  <TableCell sx={{ color: "primary.main" }}>
                    {item.Latitude}, {item.Longitude}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.7)"
          zIndex={1}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
