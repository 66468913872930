import { createTheme, alpha } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#29b6f6",
      light: alpha("rgb(41, 182, 246))", 0.2),
    },
    secondary: {
      main: "#2196f3",
      light: alpha("rgb(33, 150, 243)", 0.2),
    },
    accent: {
      main: "#ffa726",
      light: alpha("rgb(255, 168, 38)", 0.2),
    },
    gray: {
      main: "#60606050",
      light: alpha("rgb(70, 70, 70)", 0.2),
      dark: "#061e06",
      text: "#606060",
    },
    red: {
      main: "#dc143c",
      light: alpha("rgb(220, 20, 60)", 0.2),
    },
    blue: {
      main: "#000a74",
    },
    bg: {
      main: "#f6fafd",
    },
    bg1: {
      main: "#f6fafd",
    },
    success: {
      main: "#2eb67d",
    },
    warning: {
      main: "#f3bb45",
    },
    error: {
      main: "#f83e70",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial",
  },
  cardShadow: {
    boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
  },
});

export default theme;
