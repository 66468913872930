import React, { useEffect, useRef, useState } from "react";

export default function RoutingMap({ coordinates }) {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiKey = "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364";

  useEffect(() => {
    setLoading(true);
    fetch(`/api/erteams/destination/user/location`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      window.initMap = initMap;
      document.head.appendChild(script);
    };

    const initMap = () => {
      const mapOptions = {
        center: { lat: -1.304195, lng: 36.838452 },
        zoom: 12,
      };

      const mapInstance = new window.google.maps.Map(
        mapRef.current,
        mapOptions
      );
      setMap(mapInstance);

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRendererInstance =
        new window.google.maps.DirectionsRenderer();
      directionsRendererInstance.setMap(mapInstance);
      setDirectionsRenderer(directionsRendererInstance);

      // Only calculate route if data is available
      if (data.length > 0) {
        calculateRoute(directionsService, directionsRendererInstance);
      }
    };

    loadScript();
  }, [data]);

  const calculateRoute = (directionsService, directionsRenderer) => {
    if (data.length > 0) {
      const destinationCoordinates = {
        lat: parseFloat(data[0].Latitude),
        lng: parseFloat(data[0].Longitude),
      };
      const request = {
        origin: {
          lat: parseFloat(coordinates.latitude),
          lng: parseFloat(coordinates.longitude),
        },

        // origin: {
        //   lat: -1.304195,
        //   lng: 36.838452,
        // },
        destination: destinationCoordinates,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error("Error fetching directions:", status);
        }
      });
    }
  };

  return <div ref={mapRef} style={{ width: "100%", height: "45rem" }} />;
}
