import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon,
  AccountCircle as AccountCircleIcon,
  Lock as LockIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import UserAccount from "../Users/userAccount";
import EditUserDetails from "../Users/editUserDetails";
import ChangePassword from "../Users/changePassword";
import { useAuth } from "../utils/AuthContext";

const LoadingScreen = () => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 1)",
      zIndex: 1300, // Ensure it covers other components
    }}
  >
    <CircularProgress />
  </Box>
);

export default function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleAccount, setToggleAccount] = useState(false);
  const [toggleEditDetails, setToggleEditDetails] = useState(false);
  const [toggleChangePass, setToggleChangePass] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const { user, logout } = useAuth();

  const handleLogout = () => {
    // logout();
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          color: "white",
          width: "100%",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(props.open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            {/* {user?.name} */}
          </Typography>
          <IconButton color="inherit" onClick={handleClick}>
            <ArrowDropDownIcon />
            <AccountCircleIcon />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setToggleAccount(true);
              handleClose();
            }}
          >
            <AccountCircleIcon sx={{ mr: 1 }} /> Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              setToggleEditDetails(true);
              handleClose();
            }}
          >
            <PersonIcon sx={{ mr: 1 }} /> Edit Details
          </MenuItem>
          <MenuItem
            onClick={() => {
              setToggleChangePass(true);
              handleClose();
            }}
          >
            <LockIcon sx={{ mr: 1 }} /> Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleLogout();
              handleClose();
            }}
          >
            <LogoutIcon sx={{ mr: 1 }} /> Logout
          </MenuItem>
        </Menu>

        <UserAccount
          onClose={() => {
            setToggleAccount(false);
          }}
          open={toggleAccount}
          // currentUser={user}
        />
        <EditUserDetails
          open={toggleEditDetails}
          onClose={() => {
            setToggleEditDetails(false);
          }}
          // currentUser={user}
        />
        <ChangePassword
          open={toggleChangePass}
          onClose={() => {
            setToggleChangePass(false);
          }}
          // currentUser={user}
        />
      </Box>
    </>
  );
}
