import {
  People,
  Logout,
  Person,
  Notifications,
  ChevronLeft,
  ChevronRight,
  QueryStats,
  AttachMoney,
  LocationCity,
  Home,
  Engineering,
  HomeMax,
  HomeMini,
  HomeOutlined,
  Map,
  DataArray,
  DataObjectSharp,
  Dataset,
  Newspaper,
  DatasetLinked,
  Settings,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Typography } from "@mui/material";
import Header from "../../components/utils/Header";
import logo from "../../assets/imgs/logo.png";
import DeveloperSection from "./DeveloperSection";

// import { useAuth } from "../utils/AuthContext";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// Horizontal Line with Circles Component

const HorizontalLineWithCircles = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: "primary.main",
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
          height: "2px",
          backgroundColor: "primary.main",
        }}
      />
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: "primary.main",
        }}
      />
    </Box>
  );
};

function Navbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  // const { logout } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    { text: "Landing", icon: <Home />, path: "" },
    { text: "Reports", icon: <Map />, path: "reports" },
    { text: "Ambulex Operations", icon: <Map />, path: "emts" },
    { text: "Data", icon: <DatasetLinked />, path: "data" },
    { text: "News", icon: <Newspaper />, path: "news" },
  ];

  const handleLogout = () => {
    console.log("logout came here");
    // logout();
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "primary.main" }}
      >
        <Toolbar>
          <Header
            setUser={props.setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: 2,
              marginTop: 2,
            }}
          >
            <img src={logo} width={150} loading="lazy" alt="Logo" />
            <HorizontalLineWithCircles />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <List>
          {menuItems.map((item) => (
            <ListItem
              key={item.text}
              button
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path}
              sx={{
                color: "blue.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "#fff",
                  "& .MuiListItemIcon": {
                    color: "#fff",
                  },
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          ))}
          <br />
          <Divider />
          <br />
          <ListItem
            button
            onClick={() => navigate("users")}
            sx={{
              color: "blue.main",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "#fff",
                "& .MuiListItemIcon": { color: "#fff" },
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <People />
            </ListItemIcon>
            <ListItemText
              primary="Users"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("settings")}
            sx={{
              color: "blue.main",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "#fff",
                "& .MuiListItemIcon": { color: "#fff" },
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <Settings />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              color: "blue.main",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "#fff",
                "& .MuiListItemIcon": { color: "#fff" },
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
        </List>
        <DeveloperSection showing={open} />
      </Drawer>
    </Box>
  );
}

export default Navbar;
