/* global google */

import React, { useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import start from "../../assets/imgs/location-sign.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 1.2921,
  lng: 36.8219,
};

function MapDirectionsRenderer(props) {
  const [directions, setDirections] = React.useState(null);

  // const [origin, setOrigin] = React.useState([{ lat: -1.304195, lng: 36.838452 }, { lat: -1.304195, lng: 36.638452 }, { lat: -1.304195, lng: 36.838452 }, { lat: -1.304195, lng: 36.638452 } ]);
  // const [destination, setDestination] = React.useState([{ lat: 1.504195, lng: 36.8384525 }, { lat: 1.504195, lng: 36.6384525 }, { lat: 1.504195, lng: 36.8384525 }, { lat: 1.504195, lng: 36.6384525 }]);

  React.useEffect(() => {
    let routes = [];
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: props.origin,
        destination: props.destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: [
          // {
          //   location: new google.maps.LatLng(-1.2011, 36.838452),
          // },
          // {
          //   location: new google.maps.LatLng(0.6018, 36.838452),
          // },
        ],
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          routes.push({
            directions: result,
          });
          setDirections({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );

    // })
  }, []);

  if (directions?.error) {
    return <h1>{directions.error}</h1>;
  }

  return (
    <>
      <DirectionsRenderer
        directions={directions?.directions}
        // options={{
        //   markerOptions: {
        //     icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        //     // icon: "http://labs.google.com/ridefinder/images/mm_20_red.png",
        //   },
        // }}
        options={{ suppressMarkers: true }}
      />

      <Marker
        position={props.origin}
        icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
        title={"Origin of the Emergency Responder"}
        // icon={{
        //   path: google.maps.SymbolPath.CIRCLE,
        //   fillOpacity: 0.6,
        //   fillColor: "green",
        //   label: "EMT",
        //   strokeWeight: 2,
        //   strokeColor: "blue",
        //   anchor: new google.maps.Point(0, 0),
        //   scale: 9,
        // }}
      />
      <Marker
        position={props.destination}
        icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
        title={"Location of the emergency"}
        // icon={{
        //   // path: google.maps.SymbolPath.CIRCLE,
        //   fillOpacity: 0.6,
        //   fillColor: "red",
        //   label: "Client",
        //   anchor: new google.maps.Point(0, 0),
        //   scale: 9,
        // }}
      />
    </>
  );
}

export default function RidesMap(props) {
  const [map, setMap] = React.useState(null);
  const [rideID, setRideID] = React.useState(null);
  const [rideCoordinates, setrideCoordinates] = React.useState(null);
  const [origin, setOrigin] = React.useState([
    { lat: -1.304195, lng: 36.838452 },
    { lat: -1.304195, lng: 36.638452 },
    { lat: -1.304195, lng: 36.838452 },
    { lat: -1.304195, lng: 36.638452 },
  ]);
  const [destination, setDestination] = React.useState([
    { lat: 1.504195, lng: 36.8384525 },
    { lat: 1.504195, lng: 36.6384525 },
    { lat: 1.504195, lng: 36.8384525 },
    { lat: 1.504195, lng: 36.6384525 },
  ]);

  useEffect(() => {
    fetch(`/api/rides/${props.rideID}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {})
      .catch((e) => {});
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364",
  });

  const onLoad = React.useCallback(function callback(map) {
    // This is where you fit the bounds when you get data!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // showRoute();
    // map.fitBounds(bounds);
    console.log("Let's seee!!!");
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={9}
      options={{ fullscreenControl: false }}
    >
      {props.origin && props.destination && (
        <MapDirectionsRenderer
          origin={props.origin}
          destination={props.destination}
        />
      )}
      <></>
      <Marker
        position={
          props.currentLocation
            ? props.currentLocation
            : { lat: 0.7631052761022764, lng: 37.563804375850445 }
        }
        // position={
        //   { lat: 0.7631052761022764, lng: 37.563804375850445 }
        // }
        // icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 0.9,
          fillColor: "yellow",
          strokeWeight: 2,
          strokeColor: "#4a80f5",
          anchor: new google.maps.Point(0, 0),
          scale: 10,
        }}
        title={"Current Location of the Emergency Responder"}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}
