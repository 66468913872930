import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { Close, Email } from "@mui/icons-material";


export default function EditUserDetails({
  open,
  onClose,
  currentUser,
}) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    email: "",
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const rfEmail = useRef();
  const rfName = useRef();

  const editDetails = () => {
    setIsLoading(true);
    setIsError("");

    let d = body;
    d.email = rfEmail.current.value;
    d.name = rfName.current.value;

    if (validateEmail(d.email)) {
      updateBody(d);
      setIsError("");
    } else {
      setIsError("Invalid email address");
      setIsLoading(false);
      return;
    }

    fetch(`/api/auth/update/${currentUser.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Failed to update user details");
    })
    .then((data) => {
      if (data.success) {
        setIsLoading(false);
        setIsError(data.message);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setIsError(data.message);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsError("Failed to update user details");
      setIsLoading(false);
    });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Account Details for {currentUser?.name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {isError}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            inputRef={rfName}
            label="Change Name"
            variant="outlined"
            margin="normal"
            defaultValue={currentUser?.name}
            fullWidth
          />
          <TextField
            inputRef={rfEmail}
            label="Change Email"
            variant="outlined"
            margin="normal"
            type="email"
            defaultValue={currentUser?.email}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={editDetails}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
