import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import StatusLegend from "./StatusLegend";
import { Box, Typography } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "45rem",
};

const center = {
  lat: -1.304195,
  lng: 36.838452,
};

export default function AllEMTs(props) {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364",
  });

  useEffect(() => {
    if (isLoaded) {
      loadMapData();
    }
  }, [isLoaded]);

  const loadMapData = () => {
    fetch("/api/erteams")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const newMarkers = data.map((item) => ({
          position: {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          },
          ID: item.ID,
          OrganizationName: item.OrganizationName,
          Name: item.Name,
          Phone: item.Phone,
          Latitude: item.Latitude,
          Longitude: item.Longitude,
          Status: item.Status,
          Service: item.Service,
          TotalResponses: item.TotalResponses,
          City: item.City,
          Address: item.Address,
          LandMark: item.LandMark,
          BuildingName: item.BuildingName,
          OfficerNumber: item.OfficerNumber,
          AccountStatus: item.AccountStatus,
        }));
        setMarkers(newMarkers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  const downloadMap = () => {
    const map = new window.google.maps.Map(document.createElement("div"), {
      center: center,
      zoom: 9,
    });

    markers.forEach((marker) => {
      new window.google.maps.Marker({
        position: marker.position,
        map: map,
      });
    });

    const mapImage = new Image();
    mapImage.crossOrigin = "Anonymous";
    mapImage.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = mapImage.width;
      canvas.height = mapImage.height;
      const context = canvas.getContext("2d");
      context.drawImage(mapImage, 0, 0);

      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "map.png";
      link.click();
    };
    mapImage.src =
      "https://maps.googleapis.com/maps/api/staticmap?" +
      new URLSearchParams({
        center: `${center.lat},${center.lng}`,
        zoom: "9",
        size: "800x600",
        markers: markers
          .map((marker) => `${marker.position.lat},${marker.position.lng}`)
          .join("|"),
        key: "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364",
      });
  };

  const downloadData = () => {
    const rows = markers.map((marker) => [
      marker.ID,
      marker.OrganizationName,
      marker.Name,
      marker.Phone,
      marker.Longitude,
      marker.Latitude,
      marker.Status,
      marker.Service,
      marker.TotalResponses,
      marker.City,
      marker.Address,
      marker.LandMark,
      marker.BuildingName,
      marker.OfficerNumber,
      marker.AccountStatus,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "ID",
        "OrganizationName",
        "Name",
        "Phone",
        "Longitude",
        "Latitude",
        "Status",
        "Service",
        "TotalResponses",
        "City",
        "Address",
        "LandMark",
        "BuildingName",
        "OfficerNumber",
        "AccountStatus",
      ].join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "mapdata.csv");
    document.body.appendChild(link);
    link.click();
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      options={{ fullscreenControl: false }}
    >
      {markers.map((marker) => (
        <Marker
          key={marker.ID}
          position={marker.position}
          onClick={() => handleMarkerClick(marker)}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            fillOpacity: 0.9,
            fillColor: getStatusColor(marker.Status),
            strokeWeight: 2,
            strokeColor: "white",
            anchor: new window.google.maps.Point(0, 0),
            scale: 10,
          }}
          title={marker.Type}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.position}
          onCloseClick={handleCloseInfoWindow}
        >
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              boxShadow: 3,
              maxWidth: 300,
            }}
          >
            <Typography variant="h6" color="primary" gutterBottom>
              {selectedMarker.OrganizationName}
            </Typography>
            <Typography variant="body2">
              <strong>Name:</strong> {selectedMarker.Name}
            </Typography>
            <Typography variant="body2">
              <strong>Phone:</strong> {selectedMarker.Phone}
            </Typography>
            <Typography variant="body2">
              <strong>Longitude:</strong> {selectedMarker.Longitude}
            </Typography>
            <Typography variant="body2">
              <strong>Latitude:</strong> {selectedMarker.Latitude}
            </Typography>
            <Typography variant="body2">
              <strong>Status:</strong> {selectedMarker.Status}
            </Typography>
            <Typography variant="body2">
              <strong>Service:</strong> {selectedMarker.Service}
            </Typography>
            <Typography variant="body2">
              <strong>Total Responses:</strong> {selectedMarker.TotalResponses}
            </Typography>
            <Typography variant="body2">
              <strong>City:</strong> {selectedMarker.City}
            </Typography>
            <Typography variant="body2">
              <strong>Address:</strong> {selectedMarker.Address}
            </Typography>
            <Typography variant="body2">
              <strong>LandMark:</strong> {selectedMarker.LandMark}
            </Typography>
            <Typography variant="body2">
              <strong>Building Name:</strong> {selectedMarker.BuildingName}
            </Typography>
            <Typography variant="body2">
              <strong>Officer Number:</strong> {selectedMarker.OfficerNumber}
            </Typography>
            <Typography variant="body2">
              <strong>Account Status:</strong> {selectedMarker.AccountStatus}
            </Typography>
          </Box>
        </InfoWindow>
      )}
      <div className="download">
        <div>
          <a
            onClick={() => {
              downloadData();
            }}
            role="button"
          >
            <i className="fa fa-download"></i>Data
          </a>
        </div>
        <div>
          <a
            onClick={() => {
              downloadMap();
            }}
            role="button"
          >
            <i className="fa fa-download"></i>Map
          </a>
        </div>
      </div>
      <StatusLegend />
    </GoogleMap>
  ) : (
    <></>
  );
}
const getStatusColor = (status) => {
  switch (status) {
    case "dormant":
      return "red";
    case "arrived":
      return "teal";
    case "In Motion":
      return "blue";
    case "Completed":
      return "green";
    default:
      return "orange";
  }
};
