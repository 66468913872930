import {
  Book,
  CachedOutlined,
  PeopleAltRounded,
  TaskAltRounded,
} from "@mui/icons-material";
import { Box, Card, Grid, Grid2, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  FaHelmetSafety,
  FaMoneyBillTrendUp,
  FaScroll,
  FaXmark,
} from "react-icons/fa6";
import { FaMapMarked, FaSearch } from "react-icons/fa";
import GaugeChart from "react-gauge-chart";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import html2canvas from "html2canvas";
import CustomPieChart from "../Stats/CustomPieChart";
import CustomBarChart from "../Stats/CustomBarChart";
import { MdAttachMoney, MdOutlineNewLabel } from "react-icons/md";
import StackedAreaChart from "../Stats/StackedAreaChart";

const DivCard = ({ icon: Icon, title, count, subtext }) => (
  <Paper
    sx={{
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      height: "100%",
    }}
  >
    <Box sx={{ display: "flex", alignItems: "flex-start", flexGrow: 1 }}>
      <Icon style={{ color: "#000a74", fontSize: "2rem" }} />
    </Box>
    <Box
      sx={{ display: "flex", flexDirection: "column", marginLeft: "1.5rem" }}
    >
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red.main",
          fontWeight: "800",
        }}
      >
        {count}
      </Typography>
      <Typography variant="body2" sx={{ color: "gray.text" }}>
        {subtext}
      </Typography>
    </Box>
  </Paper>
);

const Landing = (props) => {
  const [activeToday, setActiveToday] = useState(0);
  const [data, setData] = useState(null);
  const [reportDist, setReportDist] = useState([]);
  const [recievedGBV, setRecievedGBV] = useState(null);
  const [totalreports, setTotalReports] = useState(null);
  const [totalgbv, setTotalgbv] = useState(null);
  const [totalme, setTotalme] = useState(null);
  const [recievedME, setRecievedME] = useState(null);
  const [aspect, setAspect] = useState(1.5);
  const [gbvStatus, setGBVStatus] = useState();
  const [meStatus, setMEStatus] = useState();
  const [genderDist, setGenderDist] = useState();
  const [monthly, setMonthly] = useState();
  const [emtME, setEmtME] = useState(null);
  const [emtGBV, setEmtGBV] = useState(null);
  const [unique, setUnique] = useState();
  const [unique2, setUnique2] = useState();
  const [aspect2, setAspect2] = useState(2.1);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();
  const p4ref = useRef();

  const b1ref = useRef();

  const l1ref = useRef();
  const l2ref = useRef();

  const act = useRef();

  const handleScroll = () => {
    if (act.current) {
      act.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    fetch(`/api/reports/type`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
        const dist = data.result.map((item) => ({
          name: item.Type,
          value: parseInt(item.count),
        }));

        setReportDist(dist);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/status/type/GBV/Received`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        const count = data.result ? parseInt(data.result[0].count) : 0;
        setRecievedGBV(count);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/paginated/0`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTotalReports(data?.total);
        setTotalgbv(data?.gbv);
        setTotalme(data?.me);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/status/type/ME/Received`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        const count = data.result ? parseInt(data.result[0].count) : 0;
        setRecievedME(count);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/type/count/GBV`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const st = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Status;
          obj.value = parseInt(item.count);
          st.push(obj);
        });
        setGBVStatus(st);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/type/count/ME`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const st = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Status;
          obj.value = parseInt(item.count);
          st.push(obj);
        });
        setMEStatus(st);
      })
      .catch((e) => {});
  }, []);

  const styles = {
    cardShadow: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
      borderRadius: 0,
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    divStyle: {
      width: "10px",
      height: "100%",
      backgroundColor: "#FFA500",
    },
  };

  useEffect(() => {
    fetch(`/api/reports/gender`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const dist = [];
        data.result.map((item) => {
          const obj = {};
          obj.name = item.Gender;
          obj.value = parseInt(item.count);
          dist.push(obj);
        });
        setGenderDist(dist);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/monthly/2024-01-01/2025-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const md = [];
        data.result.map((item) => {
          const obj = {};
          obj.date = item.datetime;
          obj.name =
            item.datetime.split("-")[1] == "01"
              ? "Jan"
              : item.datetime.split("-")[1] == "02"
              ? "Feb"
              : item.datetime.split("-")[1] == "03"
              ? "Mar"
              : item.datetime.split("-")[1] == "04"
              ? "Apr"
              : item.datetime.split("-")[1] == "05"
              ? "May"
              : item.datetime.split("-")[1] == "06"
              ? "Jun"
              : item.datetime.split("-")[1] == "07"
              ? "Jul"
              : item.datetime.split("-")[1] == "08"
              ? "Aug"
              : item.datetime.split("-")[1] == "09"
              ? "Sep"
              : item.datetime.split("-")[1] == "10"
              ? "Oct"
              : item.datetime.split("-")[1] == "11"
              ? "Nov"
              : "Dec";
          obj.value = item.reports_count;
          md.push(obj);
        });
        setMonthly(md);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/monthly/emt/GBV/2023-01-01/2024-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        let gbvData = [];
        const unique1 = [...new Set(data.result.map((item) => item.Name))];
        setUnique(unique1);
        let d = [
          {
            name: "Jan",
          },
          {
            name: "Feb",
          },
          {
            name: "Mar",
          },
          {
            name: "Apr",
          },
          {
            name: "May",
          },
          {
            name: "Jun",
          },
          {
            name: "Jul",
          },
          {
            name: "Aug",
          },
          {
            name: "Sep",
          },
          {
            name: "Oct",
          },
          {
            name: "Nov",
          },
          {
            name: "Dec",
          },
        ];

        unique1.forEach((item) => {
          let i = data.result
            .map((e) => {
              return e.Name;
            })
            .indexOf(item);
          d[0][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "01"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[1][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "02"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[2][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "03"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[3][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "04"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[4][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "05"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[5][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "06"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[6][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "07"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[7][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "08"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[8][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "09"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[9][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "10"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[10][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "11"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[11][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "12"
              ? parseInt(data.result[i].reports_count)
              : 0;
        });
        setEmtGBV(d);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/reports/monthly/emt/ME/2023-01-01/2024-01-01`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const unique1 = [...new Set(data.result.map((item) => item.Name))];
        setUnique2(unique1);
        let d = [
          {
            name: "Jan",
          },
          {
            name: "Feb",
          },
          {
            name: "Mar",
          },
          {
            name: "Apr",
          },
          {
            name: "May",
          },
          {
            name: "Jun",
          },
          {
            name: "Jul",
          },
          {
            name: "Aug",
          },
          {
            name: "Sep",
          },
          {
            name: "Oct",
          },
          {
            name: "Nov",
          },
          {
            name: "Dec",
          },
        ];

        unique1.forEach((item) => {
          let i = data.result
            .map((e) => {
              return e.Name;
            })
            .indexOf(item);
          d[0][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "01"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[1][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "02"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[2][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "03"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[3][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "04"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[4][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "05"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[5][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "06"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[6][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "07"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[7][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "08"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[8][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "09"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[9][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "10"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[10][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "11"
              ? parseInt(data.result[i].reports_count)
              : 0;
          d[11][item] =
            i !== -1 && data.result[i].datetime.split("-")[1] === "12"
              ? parseInt(data.result[i].reports_count)
              : 0;
        });
        setEmtME(d);
      })
      .catch((e) => {});
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <Box>
      <Grid2 container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {["GBV", "ME"].map((type, index) => (
          <Grid2 item size={{ xs: 4, sm: 4, md: 6 }} key={type}>
            <Card sx={styles.cardShadow}>
              <Box sx={styles.divStyle} />
              <Typography
                p={1}
                variant="body2"
                sx={{ fontWeight: "600", color: "blue.main" }}
              >
                {type}
              </Typography>
            </Card>
            <Grid2 container spacing={2} size={{ xs: 4, sm: 8, md: 12 }}>
              <Grid2 container spacing={2} alignItems="stretch">
                <Grid2 item size={{ xs: 2, sm: 2, md: 4 }}>
                  <Grid2 container direction="column" spacing={2}>
                    {type === "GBV" ? (
                      <>
                        <Grid2 item>
                          <DivCard
                            icon={FaScroll}
                            title="Total GBV Reports"
                            count={reportDist ? reportDist[0]?.value : 0}
                            subtext="Total GBV Reports"
                          />
                        </Grid2>
                        <Grid2 item>
                          <DivCard
                            icon={FaMapMarked}
                            title="New GBV Reports"
                            count={recievedGBV ? recievedGBV : 0}
                            subtext="New GBV Reports"
                          />
                        </Grid2>
                      </>
                    ) : (
                      <>
                        <Grid2 item>
                          <DivCard
                            icon={FaMoneyBillTrendUp}
                            title="Total Medical Reports"
                            count={reportDist?.[1]?.value || 0}
                            subtext="Total Medical Reports"
                          />
                        </Grid2>

                        <Grid2 item>
                          <DivCard
                            icon={MdAttachMoney}
                            title="New Medical Reports"
                            count={recievedME ? recievedME : 0}
                            subtext="New Medical Reports"
                          />
                        </Grid2>
                      </>
                    )}
                  </Grid2>
                </Grid2>

                <Grid2 item size={{ xs: 6, sm: 6, md: 8 }}>
                  <Paper sx={{ height: "100%", padding: "1rem" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: "blue.main",
                        marginBottom: "0.5rem",
                        fontWeight: "600",
                      }}
                    >
                      {type}
                    </Typography>
                    <GaugeChart
                      nrOfLevels={4}
                      colors={["#DC143C", "#191970", "#000000", "#FFD700"]}
                      arcWidth={0.3}
                      percent={
                        data
                          ? (
                              parseInt(totalreports) /
                              parseInt(reportDist ? reportDist[0]?.value : 1)
                            ).toFixed(2)
                          : 0
                      }
                      textColor="gray"
                    />
                  </Paper>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
      <Card sx={styles.cardShadow}>
        <Box sx={styles.divStyle} />
        <Typography
          p={1}
          variant="body2"
          sx={{ fontWeight: "600", color: "blue.main", color: "blue.main" }}
        >
          Reports Distribution and Status
        </Typography>
      </Card>
      <Grid2 container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid2 item size={{ xs: 4, sm: 4, md: 4 }}>
          <Paper sx={{ padding: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                Reports Distribution
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(reportDist);
                  }}
                />
              </Box>
            </Box>
            {reportDist && (
              <CustomPieChart
                data={reportDist}
                colors={["orange", "red"]}
                aspect={aspect}
              />
            )}
          </Paper>
        </Grid2>
        <Grid2 item size={{ xs: 4, sm: 4, md: 4 }}>
          <Paper sx={{ padding: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                GBV Status
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(reportDist);
                  }}
                />
              </Box>
            </Box>
            {gbvStatus && (
              <CustomPieChart
                data={gbvStatus}
                colors={["orange", "red", "gray", "green"]}
                aspect={aspect}
              />
            )}
          </Paper>
        </Grid2>
        <Grid2 item size={{ xs: 4, sm: 4, md: 4 }}>
          <Paper sx={{ padding: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                ME Status
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(gbvStatus);
                  }}
                />
              </Box>
            </Box>
            {meStatus && (
              <CustomPieChart
                data={meStatus}
                colors={["orange", "red", "gray", "green"]}
                aspect={aspect}
              />
            )}
          </Paper>
        </Grid2>
      </Grid2>
      <Card sx={styles.cardShadow}>
        <Box sx={styles.divStyle} />
        <Typography
          p={1}
          variant="body2"
          sx={{ fontWeight: "600", color: "blue.main" }}
        >
          Gender and monthly Summary
        </Typography>
      </Card>
      <Grid2
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ alignItems: "stretch" }}
      >
        <Grid2 item size={{ xs: 4, sm: 4, md: 4 }}>
          <Paper sx={{ padding: "1rem", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                Gender Distribution
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p4ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(genderDist);
                  }}
                />
              </Box>
            </Box>
            {genderDist && (
              <CustomPieChart
                data={genderDist}
                colors={["purple", "blue"]}
                aspect={props.showing ? 1.5 : 1.2}
              />
            )}
          </Paper>
        </Grid2>
        <Grid2 item size={{ xs: 4, sm: 4, md: 8 }}>
          <Paper sx={{ padding: "1rem", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                Monthly Summary
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(monthly);
                  }}
                />
              </Box>
            </Box>
            {monthly && (
              <CustomBarChart
                data={monthly}
                aspect={props.showing ? 1.5 : 2.5}
              />
            )}
          </Paper>
        </Grid2>
      </Grid2>
      <Card sx={styles.cardShadow}>
        <Box sx={styles.divStyle} />
        <Typography
          p={1}
          variant="body2"
          sx={{ fontWeight: "600", color: "blue.main" }}
        >
          EMT Perfomance
        </Typography>
      </Card>
      <Grid2 container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid2 item size={{ xs: 4, sm: 4, md: 6 }}>
          <Paper sx={{ padding: "1rem", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                GBV
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(l1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(emtGBV);
                  }}
                />
              </Box>
            </Box>
            {emtGBV && (
              <StackedAreaChart
                unique={unique}
                data={emtGBV}
                aspect={props.showing ? aspect2 : 2}
              />
            )}
          </Paper>
        </Grid2>
        <Grid2 item size={{ xs: 4, sm: 4, md: 6 }}>
          <Paper sx={{ padding: "1rem", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray.text", fontWeight: "600" }}>
                ME
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(l2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(emtME);
                  }}
                />
              </Box>
            </Box>
            {emtME && (
              <StackedAreaChart
                unique={unique2}
                data={emtME}
                aspect={props.showing ? aspect2 : 2}
              />
            )}
          </Paper>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Landing;
