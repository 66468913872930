import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box, AppBar, Toolbar } from "@mui/material";
import Navbar from "../components/utils/Navbar";
import Header from "../components/utils/Header"; // Assuming this is your header
import Landing from "../components/Home/Landing";
import MapsHome from "../components/Map/MapsHome";
import DataPage from "../components/DataPage/DataPage";
import UserHome from "../components/UsersF/UserHome";
import NewsHome from "../components/News/NewsHome";
import Settings from "../components/Settings/Settings";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import OperationsHome from "../components/Operations/OperationsHome";

const drawerWidth = 240;

function Home() {
  const [setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  
 
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", pt: 8 }}> 
        <Navbar setUser={setUser} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="" element={<Landing />} />
            <Route path="reports" element={<MapsHome />} />
            <Route path="emts" element={<OperationsHome />} />
            <Route path="data" element={<DataPage />} />
            <Route path="news" element={<NewsHome />} />
            <Route path="users" element={<UserHome />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
