import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Legend from "../Map/Legend";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -1.304195,
  lng: 36.838452,
};

export default function MyMap(props) {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364",
  });

  useEffect(() => {
    if (isLoaded) {
      loadMapData();
    }
  }, [isLoaded]);

  const loadMapData = () => {
    fetch("/api/reports")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        const newMarkers = data.map((item) => ({
          position: {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
          },
          ID: item.ID,
          Phone: item.Phone,
          Type: item.Type,
          Date: item.createdAt,
          Latitude: item.Latitude,
          Longitude: item.Longitude,
          Status: item.Status,
          ER_ID: item.ER_ID,
        }));
        setMarkers(newMarkers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  const downloadMap = () => {
    const map = new window.google.maps.Map(document.createElement("div"), {
      center: center,
      zoom: 9,
    });

    markers.forEach((marker) => {
      new window.google.maps.Marker({
        position: marker.position,
        map: map,
      });
    });

    const mapImage = new Image();
    mapImage.crossOrigin = "Anonymous";
    mapImage.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = mapImage.width;
      canvas.height = mapImage.height;
      const context = canvas.getContext("2d");
      context.drawImage(mapImage, 0, 0);

      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "map.png";
      link.click();
    };
    mapImage.src =
      "https://maps.googleapis.com/maps/api/staticmap?" +
      new URLSearchParams({
        center: `${center.lat},${center.lng}`,
        zoom: "9",
        size: "800x600",
        markers: markers
          .map((marker) => `${marker.position.lat},${marker.position.lng}`)
          .join("|"),
        key: "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364",
      });
  };

  const downloadData = () => {
    const rows = markers.map((marker) => [
      marker.ID,
      marker.Phone,
      marker.Type,
      marker.Date,
      marker.Longitude,
      marker.Latitude,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["ID", "Phone", "Type", "Date", "Longitude", "Latitude"].join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "mapdata.csv");
    document.body.appendChild(link);
    link.click();
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      options={{ fullscreenControl: false }}
    >
      {markers.map((marker) => (
        <Marker
          key={marker.ID}
          position={marker.position}
          onClick={() => handleMarkerClick(marker)}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            fillOpacity: 0.9,
            fillColor: getStatusColor(marker.Status),
            strokeWeight: 2,
            strokeColor: "white",
            anchor: new window.google.maps.Point(0, 0),
            scale: 10,
          }}
          title={marker.Type}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.position}
          onCloseClick={handleCloseInfoWindow}
        >
          <div>
            <p>Type: {selectedMarker.Type}</p>
            <p>Assigned To: {selectedMarker.name}</p>
            <p>Status: {selectedMarker.Status}</p>
          </div>
        </InfoWindow>
      )}
      <div className="download">
        <div>
          <a
            onClick={() => {
              downloadData();
            }}
            role="button"
          >
            <i className="fa fa-download"></i>Data
          </a>
        </div>
        <div>
          <a
            onClick={() => {
              downloadMap();
            }}
            role="button"
          >
            <i className="fa fa-download"></i>Map
          </a>
        </div>
      </div>
      <Legend />
    </GoogleMap>
  ) : (
    <></>
  );
}
const getStatusColor = (status) => {
  switch (status) {
    case "Resolved":
      return "green";
    case "Received":
      return "blue";
    case "In Progress":
      return "orange";
    default:
      return "red"; // Default color if status is unknown
  }
};
